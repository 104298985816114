import React, { useState, useEffect } from "react";
import { Row, Col, Table, Button, Image, Select, Spin } from "antd";
import "../../styles/shoppingcart.css";
import * as FetchService from "../../services/FetchService/FetchService";
import * as DispatchService from "../../services/DispatchService/DispatchService";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { MinusOutlined } from "@ant-design/icons";
import cart from "../../assets/images/cart.jpg";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
const { Option } = Select;
const Shoppingcart = () => {
  const [addCart, setAddCart] = useState([]);
  const [timeList, setTimeList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedTiming, setSelectedTiming] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [ogSelected, setOGSelected] = useState(false);
  const [evngSelected, setEvngSelected] = useState(false);
  const [afternoonSelected, setAfternoonSelected] = useState(false);
  const [daSelected, setDASelected] = useState(false);
  const [orderplaceds, setOrderplaceds] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const loggedInUserRole = useSelector((state) => state.user.userID);
  const getuserid = loggedInUserRole;
  const receivedData = location.state.data;
  const dispatch = useDispatch();
  //   const Additem = (food) => {

  //     // Check if there are remaining tokens
  //     if (food.remaining_token > 0 && !isAdding) {
  //       food.count = 1;

  //       setIsAdding(true)
  //       // Set isAdding to true before dispatch
  //       dispatch(DispatchService.AddItem(food))
  //         .then((res) => {
  //           // Move the console.log here
  // setIsAdding(false)
  //           if (res.message === 'updated') {
  //             getData();
  //           } else {
  //             // console.log("hello");
  //           }
  //           setIsAdding(false);
  //         })
  //         .catch((error) => {

  //           console.error("Error adding item to cart:", error);
  //         })

  //     } else {
  //       // console.log("No remaining tokens left.");
  //     }
  //   };

  const removeitem = (foodItemId) => {
    let foodId = foodItemId.food_id;
    if (foodItemId.quantity !== 0) {
      dispatch(DispatchService.RemoveItem({ foodId }))
        .then((response) => {
          const updatedCartItems = addCart.map((item) => {
            if (item.food_id === foodId) {
              return { ...item, quantity: item.quantity - 1 };
            }
            return item;
          });

          if (response.length === 0) {
            const filteredCartItems = updatedCartItems.filter(
              (item) => item.food_id !== foodId
            );
            setAddCart(filteredCartItems);
          } else {
            setAddCart(updatedCartItems);
          }
        })

        .catch((error) => {
          console.error("Error removing item from cart:", error);
        });
    }
  };
  const getData = () => {
    dispatch(DispatchService.ShoppingCart({ getuserid })).then((response) => {
      setAddCart(response);
    });
  };
  useEffect(() => {
    getData();
  }, []);
  const currentTime = new Date();
  const currentEndTime = new Date();
  const checkDateAndTime = new Date();


const orderplaced = () => {
  let showSwal = true;
  setOrderplaceds(true);
  const selectedOrders = [];

  let allItemsValid = true; 

  addCart.forEach((food) => {
    const currentOptions = food.option;
    const matchingTiming = timeList.find((time) =>
      currentOptions.includes(time.option)
    );

    if (!matchingTiming) {
      allItemsValid = false; 
      return;
    }

    const selectedOption = selectedOptions.find(
      (option) => option.food_id === food.food_id
    )?.option;
    const selectedOrderTime = selectedTiming.find(
      (option) => option.food_id === food.food_id
    )?.option;

    if (!selectedOption || !selectedOrderTime) {
      allItemsValid = false; 
      return;
    }

    const [hours, minutes] = matchingTiming.start_time.split(":").map(Number);
    const [hrs, mints] = matchingTiming.end_time.split(":").map(Number);

    currentTime.setHours(hours, minutes, 0, 0);
    currentEndTime.setHours(hrs, mints, 0, 0);

    const existingOrder = selectedOrders.find(
      (order) => order.food_id === food.food_id
    );

    if (!existingOrder) {
      selectedOrders.push({
        ...food,
        selectedOption,
        selectedOrderTime,
      });
    }
  });

  if (!allItemsValid) {
    // Show an alert if validation fails
    Swal.fire({
      title: "Incomplete Selection",
      text: "Please select both an option and timing for all items in your cart before placing the order.",
      icon: "warning",
    });
    setOrderplaceds(false);
    return;
  }

  if (selectedOrders.length > 0) {
    dispatch(DispatchService.PlacedOrder({ orders: selectedOrders }))
      .then((result) => {
        if (result.status === 200) {
          Swal.fire({
            title: "Your order has been placed successfully",
            icon: "success",
          })
            .then(() => {
              deletecart();
            })
            .then(() => {
              navigate("/view_order", { state: { data: addCart } });
            })
            .catch(() => {
              setOrderplaceds(false);
              showSwal = false;
            });
        } else {
          Swal.fire({
            title: "Failed to place order",
            icon: "error",
          });
        }
      });
  } else if (showSwal === true) {
    Swal.fire({
      title: "Can't place order now",
      icon: "error",
    }).then(() => {
      deletecart();
    });
  }
};

  const deletecart = () => {
    dispatch(DispatchService.DeleteCart()).then((res) => {
      setAddCart([]);
    });
  };
  const checkFoodName = (data) => {
    let TotalTokens = data;
    const findRemainingTokens = receivedData?.find((item) => {
      return item.food_id === TotalTokens;
    });
    if (findRemainingTokens !== undefined) {
      return findRemainingTokens.name;
    }
  };
  const checkImage = (image) => {
    let TotalTokens = image;
    const findRemainingTokens = receivedData?.find((item) => {
      return item.food_id === TotalTokens;
    });
    if (findRemainingTokens !== undefined) {
      let imagePath = findRemainingTokens.path;
      let imageUrl = `https://foodorde-api.aleaspeaks.com/${imagePath}`;
      return imageUrl;
    }
  };
  useEffect(() => {
    dispatch(FetchService.TimeList()).then((res) => {
      setTimeList(res);
    });
  }, []);
  const handleOptionChange = (foodId, option) => {
    console.log(foodId ,"foodId")
    setSelectedOptions((prevOptions) => {
      // Update selected options preserving the existing selected options
      const updatedOptions = prevOptions.filter(
        (option) => option.food_id !== foodId
      );
      return [...updatedOptions, { food_id: foodId, option: option }];
    });
  };
  const handleOptionTiming = (foodId, option) => {
    console.log(foodId ,"foodIs")
    setSelectedTiming((prevOptions) => {
      // Update selected options preserving the existing selected options
      const updatedOptions = prevOptions.filter(
        (option) => option.food_id !== foodId
      );

      return [...updatedOptions, { food_id: foodId, option: option }];
    });
  };
  useEffect(() => {

    // Check if both OG and DA options are selected
    const isOGSelected = selectedOptions.some(
      (option) => option.option === "OG"
    );
    const isDASelected = selectedOptions.some(
      (option) => option.option === "DA"
    );
    const isEvening = selectedTiming.some(
      (option) => option.option === "Evening"
    );
    const isAfternoon = selectedTiming.some(
      (option) => option.option === "Afternoon"
    );
    setOGSelected(isOGSelected);
    setEvngSelected(isEvening);
    setAfternoonSelected(isAfternoon);
    setDASelected(isDASelected);
  }, [selectedOptions]);

  // const isPlaceOrderDisabled = !ogSelected || !daSelected;
  const isPlaceOrderDisabled = !(ogSelected || daSelected || evngSelected || afternoonSelected);
  const items = [
    {
      key: "OG",
      label: "OG",
    },
    {
      key: "DA",
      label: "DA",
    },
  ];
  const OrderTiming = [
    {
      key: "Evening",
      label: "Evening",
    },
    {
      key: "Afternoon",
      label: "Afternoon",
    },
  ];
  // table code start
  const columns = [
    {
      title: <span className="custom-title">Product</span>,
      dataIndex: "food_id",
      className: "custom-tablename-column",
      key: "name",
      render: (img, record) => (
        <div
          style={{
            display: "flex",
          }}
        >
          <Image
            src={
              checkImage(img)
                ? checkImage(img)
                : `https://foodorde-api.aleaspeaks.com/${record.path}`
            }
            preview={false}
            width={40}
            height={40}
            style={{
              borderRadius: "40px",
            }}
          />
          <span style={{ margin: "5px" }}>
            {checkFoodName(img) ? checkFoodName(img) : img}
          </span>
        </div>
      ),
    },
    {
      title: <span className="custom-title">Quantity</span>,
      dataIndex: "quantity",
      key: "quantity",
      className: "custom-tablename-column",
    },
    {
      //test
      //663dee708137d3485c792e56
      //Ayushi
      //6634ec1021660829f88210ca
      title: <span className="custom-title">Action</span>,
      render: (item, i) => {
        return (
          <div className="action-btn">
            <MinusOutlined className="action" onClick={() => removeitem(item)} />
            {/* <PlusOutlined className="action" onClick={() => Additem(item)} disabled={isAdding} /> */}
          </div>
        );
      }
    },
    {
      title: <span className="custom-title">OG/DA</span>,
      dataIndex: "",
      render: (_, record) => (
        <Select
          defaultValue="Please Select"
          style={{ width: 120 }}
          onChange={(value) => handleOptionChange(record.food_id, value)}
        >
          {items.map((item) => (
            <Option key={item.key} value={item.key}>
              {item.label}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: <span className="custom-title">Order Time</span>,
      dataIndex: "",
      render: (_, record) => (
        <Select
          defaultValue="Please Select"
          style={{ width: 120 }}
          onChange={(value) => handleOptionTiming(record.food_id, value)}
          disabled={record.option?.length <= 1 ? true : false}
        >
          {OrderTiming.map((item) => (
            <Option key={item.key} value={item.key}>
              {item.label}
            </Option>
          ))}
        </Select>
      ),
    },
  ];
  return (
    <div>
      {
        loader ? (
          <div>
            <Spin
              size="large" />
          </div >
        ) : (addCart.length > 0 ? (
          <>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={addCart}
                  pagination={false}
                  className="ant-border-space"
                />
              </Col>
            </Row>
            <Button
              className="order-btn"
              onClick={() => orderplaced()}
              disabled={isPlaceOrderDisabled || orderplaceds}
            >
              Place Order
            </Button>
          </>
        ) : (
          <>
            <Row>
              <Col span={24} className="blankCart">
                <Image
                  src={cart}
                  preview={false}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "35vw",
                    height: "70vh",
                  }}
                />
              </Col>
            </Row>
          </>
        ))
      }
    </div>
  );
};
export default Shoppingcart;