import Http from "../../Http";
import { fetchDetail_Url } from "../../Helper";

//signin
export const SignIn = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "signin", body)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

//userpanel
export const CheckassignedItems = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "checkassignedItems", body)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const CreateUser = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "create-user", body)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const ViewTicket = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "viewTicket", body)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const DeleteAllTicket = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "deletAll", body)
        .then((res) => {

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const AssignTckt = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "assign", body)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const DeleteAssignTckt = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "deleteAssignTckt", body)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const UpdateAssignTckt = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "updateAssignTckt", body)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

//updateTicket
export const UpdateTckt = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "updateticket", body)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

//TimeList
export const CreateTime = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "timing", body)
        .then((res) => {
          if (res === undefined) {

          } else {
            return resolve(res.data);
          }
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const UpdateFoodTime = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "updateFoodTiming", body)
        .then((res) => {
          // return resolve(res.data);
          if (res === undefined) {

          } else {
            return resolve(res.data);
          }
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

//ListTckt
export const DeleteFormTckt = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "deleteapi", body)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

//FormtTckt
export const CreateFormTckt = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "uploadingdata", body)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

//vieworder
export const OrderHistory = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "getorderHistory", body)
        .then((res) => {
          if (res === undefined) {
            // console.log("data not found");
          } else {
            return resolve(res.data);
          }
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

//userpage
export const UserOrderList = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "userorderlist", body)

        .then((res) => {
          if (res === undefined) {
            // console.log("undefinedData", res);
          } else {
            return resolve(res.data);
          }
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const RemoveItem = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "removeitemcart", body)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const AddItem = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "additemcart", body)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const PlacedOrder = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "orderplaced", body)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const DeleteCart = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "deletecart")
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const ShoppingCart = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "shoppingcart", body)
        .then((res) => {
          if (res === undefined) {
            // console.log("data not found");
          } else {
            return resolve(res.data);
          }
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const Checkbox = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "CheckboxData", body)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const Rating = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "addrating", body)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const updateTicket = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "updateRating", body)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const AnnouncmentApi = (body) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "anncoument", body)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};


export const getRating = (value) => {
  let formData = {
    user_id: value,
  };
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "getrating", formData)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const checkToken = (data) => {
  let formData = {
    data: data,
  };
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "check_token", formData)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const CancelOrders = (data) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "cancelOrder", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const getAllRatingData = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "getTotalRating", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const addingStock = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "add-stock", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const addingHardwareStock = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "add-hardware-stock", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const allocateHardware = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "allocate-hardware-stock", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const allocateStationary = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "allocate-stationary-stock", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const addCategory = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "Category", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const deleteCategory = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "deleteCategory", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const updateCategory = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "updateCategory", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const InfraMessage = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "add-infra-form", data)
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const email = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "add-email", data)
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const getRequirment = (data) => {
 
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "getallRequirment", data)
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const DeleteEmail = (data) => {
 
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "delete-email", data)
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const updateEmail = (data) => {
 
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "update-email", data)
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const slackMsg = (data) => {
 
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "slack-msg", data)
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const UserFoodData = (data) => {
 
  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "user-food-data", data)
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

// .................add the other item ........................//
export const addingOtherStock= (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "add-other-stock", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const AllocateOtherStock= (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "allocate-other-stock", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};


export const addingFoodStock = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "add-food-stock", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const allocateFood = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "allocate-food-stock", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const updatedOtherStock = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "update-other-stock", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const updatedStationaryStock = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "update-stationary-stock", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const updatedHardwareStock = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "update-hardware-stock", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const updatedFoodStock = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "update-food-stock", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const deletehardware = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "delete-hardware-stock", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const deletestationary = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "delete-stationary-stock", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const deletefood = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "delete-food-stock", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const deleteother = (data) => {

  return () =>
    new Promise((resolve, reject) => {
      Http.post(fetchDetail_Url + "delete-other-stock", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
// export const Checking = (data) => {

//   return () =>
//     new Promise((resolve, reject) => {
//       Http.post(fetchDetail_Url + "checkcorn", data)
//         .then((res) => {
//           return resolve(res.data);
//         })
//         .catch((err) => {
//           return reject(err);
//         });
//     });
// };
// export const RefillChecking = (data) => {

//   return () =>
//     new Promise((resolve, reject) => {
//       Http.post(fetchDetail_Url + "checkRefillcorn", data)
//         .then((res) => {
//           return resolve(res.data);
//         })
//         .catch((err) => {
//           return reject(err);
//         });
//     });
// };