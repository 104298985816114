import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Select,
  Image,
  Row,
  Col,
  Input,
  Spin,
  Space,
} from "antd";
import { Card } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import "../styles/userPanel.css";
import { MdDelete } from "react-icons/md";
import { BiSolidEditAlt } from "react-icons/bi";
import { DeleteFilled } from "@ant-design/icons";
import * as FetchService from "../services/FetchService/FetchService";
import * as DispatchService from "../services/DispatchService/DispatchService";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const { Option } = Select;
const { Search } = Input;
function UserPanel() {
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [number, setNumber] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const [activeUser, setActiveUser] = useState([]);
  const [openTicket, setOpenTicket] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [allUserTicket, setAllUserTicket] = useState([]);
  const [assginedCounpons, setAssignedCounpons] = useState([]);
  const [showSearchData, setShowSearchData] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let selectedItemsList = [];
  //To store the original values of ticket table
  const [originalListingData, setOriginalListingData] = useState([]);
  const [updatefood, setUpdateFood] = useState({});
  const [form] = Form.useForm();
  const [Updateform] = Form.useForm();

  const rowClassName = (record, index) => {
    return index % 2 === 0 ? "white-row" : "lightblue-row";
  };
  useEffect(() => {
    dispatch(FetchService.getassignCouponLength())
      .then((response) => {
        setAssignedCounpons(response)
      })
  }, []);
  const checkAssignedCoupons = (data) => {

    if (assginedCounpons !== undefined && assginedCounpons?.length > 0) {
      let findCoupon = assginedCounpons?.find((item) => {
        return item?.user_id === data._id;
      });
      if (findCoupon !== undefined) {
        return findCoupon.TotalAssigned;
      } else {
        return '-';
      }
    }
  }

  const columns = [
    {
      title: "S. No.",
      dataIndex: "S_No.",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: <span className="custom-address-title">Username</span>,
      dataIndex: "username",
      className: "custom-name-column",
      key: "name",
      // ...getColumnSearchProps("username"),

      render: (img, record) => (
        <div
          style={{
            display: "flex",
            marginLeft: "60px",
          }}
        >
          <Image
            src={record.userimage}
            preview={false}
            width={30}
            height={30}
            style={{
              borderRadius: "20px",
            }}
          />
          <span style={{ margin: "5px" }}>{img}</span>
        </div>
      ),
    },
    {
      title: <span className="custom-address-title">Assign Ticket</span>,
      dataIndex: "",
      key: "x",
      render: (assign, i) => (
        <div className="btn-wrapper">
          <Button onClick={() => handleClick(assign)} className="Assign-btn">
            Assign Ticket
          </Button>
        </div>
      ),
    },
    {
      title: <span className="custom-address-title">View Ticket</span>,
      dataIndex: "",
      key: "x",
      render: (assign, i) => (
        <div className="btn-wrapper">
          <Button onClick={() => handleView(assign)} className="view-btn">
            View Ticket
          </Button>
        </div>
      ),
    },

    {
      title: <span className="custom-address-title">Assigned Coupons</span>,
      dataIndex: "",
      key: "x",
      render: (deleteAll, i) => (
        <div className="btn-wrapper">
          <p>{checkAssignedCoupons(deleteAll)}</p>

        </div>
      ),
    },
    {
      title: <span className="custom-address-title">Delete All</span>,
      dataIndex: "",
      key: "x",
      render: (deleteAll, i) => (
        <div className="btn-wrapper">
          <Button
            className="delete-wrapper"
            onClick={() => handleDeleteAll(deleteAll)}
          >
            <DeleteFilled className="del-icon" />
          </Button>
        </div>
      ),
    },
   
  ];
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoader(true);
    // Fetch user details
    dispatch(FetchService.UserDetail())
      .then((response) => {
        setItems(response);

        // Fetch ticket list
        dispatch(FetchService.FoodList())
          .then((res) => {
            setOriginalListingData(res);
            setList(res);
            setLoader(false);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  function handleClick(data) {
    setActiveUser(data);
    setOpen(true);
    let formData = {
      user_id: data._id,
    };
    // to filter items according to user

    dispatch(DispatchService.CheckassignedItems(formData))
      .then((res) => {
        let data = res;

        const filterListItems = list?.filter((item) => {
          let foodIds = data?.map((record) => record.food_id);
          return !foodIds.includes(item._id);
        });
        // to set filtered items in the dropdown
        setList(filterListItems);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const handleView = async (data) => {
    try {
      let values = {
        user_Id: data ? data._id : activeUser._id,
      };
      const resultpromise = dispatch(DispatchService.ViewTicket(values));
      const result = await Promise.resolve(resultpromise);
      if (result.status === 202 || result === "Messages not found!") {
        Swal.fire({
          title: "No ticket assign yet",
          icon: "warning",
        });
      } else {
        setAllUserTicket(result);
        setOpenTicket(true);
      }
    } catch (error) {
      console.error("Error fetching ticket data:", error);
    }
  };
  const handleDeleteAll = (Deletedata) => {
    // Check if allUserTicket is defined and has a length greater than or equal to 1
    if (allUserTicket && allUserTicket.length >= 1) {
      // Prompt user to confirm deletion
      Swal.fire({
        title: "Are you sure you want to delete all items?",
        text: "This action cannot be undone",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      }).then((result) => {
        // Check if user confirmed deletion
        if (result.isConfirmed) {
          // Perform deletion
          dispatch(DispatchService.DeleteAllTicket(Deletedata))
            .then((res) => {
              // Check if response status indicates no tickets assigned
              if (res.status === 204 || res.status === "204") {
                Swal.fire({
                  title: "No tickets assigned yet",
                  icon: "error",
                });
              } else {
                // Display success message
                Swal.fire({
                  title: "Successfully Deleted",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.error("Error deleting tickets:", error);
              // Handle error case
              Swal.fire({
                title: "Error",
                text: "An error occurred while deleting tickets",
                icon: "error",
              });
            });
        } else {
          // User canceled, do nothing
          console.log("Deletion canceled by user");
        }
      });
    } else {
      // Handle case where there are no tickets to delete
      Swal.fire({
        title: "No tickets assigned yet",
        icon: "error",
      });
    }
  };
  function close() {
    setOpen(false);
    form.resetFields();
    selectedItemsList = [];
    // to re-set all filters and save original data into list
    setList(originalListingData);
  }
  function closeTicket() {
    setOpenTicket(false);
  }
  const onFinish = (values) => {
    const user_id = activeUser._id;
    const status = 1;
    const data = { ...values.items };
    let formatData;
    // Check if data is an object
    if (typeof data === 'object' && data !== null) {
      formatData = Object.keys(data).map((key) => {
        const item = data[key];
        return {
          ...item,
          food_id: item.food_id.value
        };
      });
    } else {
      console.error("Data is not an object.");
    }
    let formData = {
      user_id: user_id,
      data: formatData,
      status: status,
    };
    if (!formData.data || Object.keys(formData.data).length === 0) {
      Swal.fire({
        title: "You haven't added any items",
        icon: "warning",
      });
    } else {
      dispatch(DispatchService.AssignTckt(formData))
        .then((res) => {
          Swal.fire({
            title: "Coupons has been allocted",
            icon: "success",
          }).then(() => {
            setOpen(false);
            // window.location.reload();
            form.resetFields();
            setList(originalListingData);
            selectedItemsList = [];
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const handleDeleteitem = (listofFood) => {
    Swal.fire({
      title: "Are you sure you want to delete this item?",
      text: "This action cannot be undone",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DispatchService.DeleteAssignTckt({ listofFood })).then(
          (res) => {
            Swal.fire({
              title: "Successfully Deleted",
              icon: "success",
            });
            if (allUserTicket.length === 1) {
              setOpenTicket(false);
            }

            setAllUserTicket(
              allUserTicket.filter((ticket) => ticket._id !== listofFood._id)
            );
          }
        );
      }
    });
  };
  const handleUpdateitem = (listofFood) => {
    setUpdateFood(listofFood);
    setUpdateModal(true);
  };
  function onUpdateFinish(values) {
    let formValues = {
      values: values,
      updatefood: updatefood,
    };
    dispatch(DispatchService.UpdateAssignTckt(formValues)).then((res) => {
      const updatedTicketList = allUserTicket.map((ticket) => {
        if (ticket._id === updatefood._id && ticket.user_id === updatefood.user_id) {
          const remainingToken = values.token_no - (ticket.token_no - ticket.remainingToken_no);
          return { ...ticket, token_no: values.token_no, remainingToken_no: remainingToken };  // return { ...ticket, ...values }; 
        }
        return ticket;
      });
      setAllUserTicket(updatedTicketList);
      Swal.fire({
        title: "Succesfully Updated",
        icon: "success",
      });
      Updateform.resetFields();
      setUpdateModal(false);
    });
  }
  function closeUpdateModal() {
    Updateform.resetFields();
    setUpdateModal(false);
  }
  const onSearch = (value) => {
    if (value !== undefined) {
      const filterItems = items.filter((searchdata) =>
        searchdata.username.toLowerCase().includes(value.toLowerCase())
      );
      if (filterItems === undefined || filterItems.length === 0) {
        Swal.fire({
          title: "No Such user find",
          icon: "error",
        });
      } else {
        setShowSearchData(filterItems);
      }
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };
  const hideSelectedValues = (data) => {
    selectedItemsList.push(data.value);
    selectedItemsList?.map((item) => {
      const itemsList = list?.filter((recrords) => {
        return item !== recrords._id;
      });
      setList(itemsList);
    })
  }
  return (
    <>
      <div className="loaderOuter">
        {loader ? (
          <div className="loaderSpin">
            <Spin size="large" />
          </div>
        ) : (
          <>
            <Space className="space">
              <Search
                placeholder="input search text"
                onSearch={onSearch}
                enterButton
                allowClear
                onKeyDown={handleKeyPress}
              />
            </Space>
            <Button onClick={()=>navigate('/register_user')}>Add User</Button>
            {showSearchData.length > 0 ? (
              <Card>
                <Table
                  className="table"
                  style={{
                    width: "100%",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                  rowClassName={rowClassName}
                  pagination={showSearchData.length}
                  columns={columns}
                  dataSource={showSearchData}
                  loading={loader}
                />
              </Card>
            ) : items.length > 0 ? (
              <Card>
                <Table
                  className="table"
                  style={{
                    width: "100%",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                  rowClassName={rowClassName}
                  pagination={items.length}
                  columns={columns}
                  dataSource={items}
                  loading={loader}
                />
              </Card>
            ) : (
              <p>No registrations from users have been recorded as of now.</p>
            )}
          </>)}

        <Modal
          visible={open}
          onOk={close}
          onCancel={close}
          footer={null}
          className="assign_model"
        >
          <Form
            className="userform"
            form={form}
            name="dynamic_form_complex"
            autoComplete="off"
            onFinish={onFinish}
            initialValues={{
              items: [{}],
            }}
          >
            <Form.List name="items">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    rowGap: 16,
                    flexDirection: "column",
                  }}
                >
                  {fields.map((field) => (
                    <>
                      <Card
                        size="small"
                        title={`Food Item ${field.name + 1}`}
                        key={field.key}
                        className="model-card"
                        extra={
                          <CloseOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        }
                      >
                        <Form.Item
                          label="Select Option"
                          name={[field.name, "food_id"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select option!",
                            },
                          ]}
                        >
                          <Select
                            style={{
                              width: 300,
                            }}
                            placeholder="Select option"
                            labelInValue={true}
                            onChange={hideSelectedValues}
                            dropdownRender={(menu) => <>{menu}</>}
                          >
                            {list
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((data, index) => (
                                <>
                                  <Option
                                    key={index}
                                    value={data._id}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Image
                                      preview={false}
                                      src={`https://foodorde-api.aleaspeaks.com/${data.path}`}
                                      style={{
                                        width: "25px",
                                        height: "25px",
                                        borderRadius: "50%",
                                      }}
                                    />
                                    <span style={{ marginLeft: "18px" }}>
                                      {data.name}
                                    </span>
                                  </Option>
                                </>
                              ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          label=" Select Token"
                          name={[field.name, "token_no"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select token!",
                            },
                          ]}
                        >
                          <Select
                            style={{
                              width: 300,
                            }}
                            placeholder="Select token"
                          >
                            {number.map((number, index) => (
                              <Select.Option key={index} value={number}>
                                {number}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Card>
                    </>
                  ))}
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    className="add-btn"
                  > + Add Item</Button>
                </div>
              )}
            </Form.List>
            <Form.Item className="btn-form">
              <Button className="formbtn" type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        {/* for view ticket */}
        <Modal
          open={openTicket}
          onOk={closeTicket}
          onCancel={closeTicket}
          footer={null}
          className="user-modal"
        >
          <Row>
            {allUserTicket === "Messages not found!" ? (
              <p>No ticket assign yet????</p>
            ) : (
              allUserTicket.map((listofFood, index) => {
                return (
                  <>
                    <Col span={8}>
                      <Card className="card-style">
                        <div key={index} className="viewImage">
                          <Image
                            className="card-image"
                            src={`https://foodorde-api.aleaspeaks.com/${listofFood.path}`}
                            preview={false}
                            style={{ height: "40vh", width: "17vw" }}
                          />
                        </div>
                        <div className="listofName">
                          <h3> {listofFood.name}</h3>
                          <p> Assigned Token :- {listofFood.token_no}</p>
                          <p> Used Token  :- {listofFood?.token_no - listofFood?.remainingToken_no}</p>
                          <p> Remaining Token  :- {listofFood?.remainingToken_no}</p>

                        </div>
                        <div>
                          <div className="btn-wrapper">
                            <Button
                              icon={<MdDelete />}
                              className="btn"
                              onClick={() => handleDeleteitem(listofFood)}
                            >
                              Delete
                            </Button>
                            <Button
                              icon={<BiSolidEditAlt />}
                              className="btn"
                              onClick={() => handleUpdateitem(listofFood)}
                            >
                              Update
                            </Button>
                          </div>
                          <Modal
                            open={updateModal}
                            onOk={closeUpdateModal}
                            onCancel={closeUpdateModal}
                            footer={null}
                          >
                            <Form
                              className="Update-Form"
                              onFinish={onUpdateFinish}
                              form={Updateform}
                            >
                              <div key={index} className="updateviewImage">
                                <Image
                                  src={`https://foodorde-api.aleaspeaks.com/${updatefood.path}`}
                                  preview={false}
                                  width={350}
                                  height={250}
                                />
                              </div>
                              <Form.Item
                                label="Token"
                                name="token_no"
                                className="dropdown-item"
                              >
                                <Select
                                  style={{
                                    width: 300,
                                  }}
                                  placeholder="Select token"
                                >
                                  {number.map((number, index) => (
                                    <Select.Option key={index} value={number}>
                                      {number}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <Form.Item>
                                <Button htmlType="submit" className="update-btn">
                                  Update
                                </Button>
                              </Form.Item>
                            </Form>
                          </Modal>
                        </div>
                      </Card>
                    </Col>
                  </>
                );
              })
            )}
          </Row>
        </Modal>
      </div>
    </>
  );
}

export default UserPanel;
