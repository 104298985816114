import React from "react";
import {
    Button,
    Row,
    Col,
    Typography,
    Form,
    Input,
    
    Spin
  } from "antd";
import '../styles/RegisterUser.css'
  import { useNavigate } from "react-router-dom";
  import { useDispatch } from "react-redux";
  import Swal from "sweetalert2";
  import * as DispatchService from "../services/DispatchService/DispatchService";
  import userimage from '../assets/images/userimage.svg';
  const { Title } = Typography;
  
const RegisterUser = ()=>{
const dispatch = useDispatch();
const navigate = useNavigate();
const onFinish = (value)=>{

dispatch(DispatchService.CreateUser(value))
.then((response)=>{
if(response.message){
    Swal.fire({
        title:`${response.message}`,
        icon:"success"
    }).then((result)=>{
        if(result.isConfirmed){
            navigate('/user_panel')
        }
    })
}
})

    }
    return(
        <Row gutter={24} justify="space-around">
        <Col
          className="signinRow"
          xs={{ span: 24 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
        >
         
          <Title className="title" level={5}>
            Enter User Detail
          </Title>
          <Form
              name="basic"
              
              onFinish={onFinish}
              className="row-col1"
            >
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
                className="register-input"
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
                  className="register-input"
              >
                <Input placeholder="email" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
                  className="register-input"
              >
                <Input.Password placeholder="Password"  />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="register-button"
                >
                Add
                </Button>
              </Form.Item>
            </Form>
        </Col>
        <Col
          className="sign-img"
          style={{ padding: 12 }}
          xs={{ span: 24 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
        >
          <div className="imageWrapper-regiser" style={{  display:'flex', justifyContent:'center',alignItems:'center'}}>
            <img src={userimage} alt="" />
          </div>
        </Col>
      </Row>
    )
}
export default RegisterUser;